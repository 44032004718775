<!-- 用户管理 -->
<template>
  <div class="container">
    <div class="main-content">
      <div class="ribbon-wrap">
        <div class="item">
          <div class="search-box">
            <span class="label">模糊搜索：</span>
            <a-input
              class="search-input"
              v-model="searchValue"
              placeholder="尝试搜索姓名、手机号等"
            />
          </div>
          <div class="select-box">
            <span class="label">用户类型：</span>
            <a-select
              class="select"
              :default-value="0"
              @change="handleSelectChange"
            >
              <a-select-option :value="0">
                普通用户
              </a-select-option>
              <a-select-option :value="1">
                业务推广
              </a-select-option>
            </a-select>
          </div>
        </div>
        <div class="item">
          <div class="btn-box" v-permission="'search'">
            <a-button @click="searchReset">
              重置
            </a-button>
            <a-button type="primary" @click="searchSubmit">
              查询
            </a-button>
          </div>
        </div>
      </div>

      <div class="main-content-header">
        <div class="item">
          <div class="title">用户列表</div>
        </div>
        <div class="item">
          <a-button v-permission="'export'">
            导出用户列表
          </a-button>
        </div>
      </div>

      <my-table
        :columns="columns"
        :data-source="dataSource"
        :pagination="pagination"
        :loading="loading"
        :scroll="{ x: 900, y: true }"
        @change="handlePaginationChange"
      >
        <template slot="type" slot-scope="record">
          <span v-if="record.isSpread">推广用户</span>
          <span v-else-if="record.isdiscountSpread">折扣用户</span>
          <span v-else>普通用户</span>
        </template>
        <template slot="avatar" slot-scope="record">
          <div class="avatar">
            <img class="img" :src="record.avatar" alt="avatar" />
          </div>
        </template>
        <template slot="action" slot-scope="record">
          <router-link :to="{ path: '/userInfo', query: { id: record.id } }">
            详情
          </router-link>
        </template>
      </my-table>
    </div>
  </div>
</template>
<script>
const userType = [
  {
    normal: 0, // 普通
    spread: 0, // 推广
    disspread: 0 // 折扣
  },
  {
    normal: 0,
    spread: 1,
    disspread: 0
  },
  {
    normal: 0,
    spread: 0,
    disspread: 1
  }
]
export default {
  data() {
    return {
      columns: [
        {
          title: '序号',
          width: 70,
          customRender: (text, record, index) => index + 1
        },
        {
          title: '用户编号',
          dataIndex: 'id',
          width: 100
        },
        {
          title: '用户昵称',
          dataIndex: 'nickname',
          width: 200
        },
        {
          title: '用户头像',
          width: 100,
          scopedSlots: {
            customRender: 'avatar'
          }
        },
        {
          title: '用户类型',
          width: 100,
          scopedSlots: {
            customRender: 'type'
          }
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 200
        },
        {
          title: '注册时间',
          dataIndex: 'createAt'
        },
        {
          title: '操作',
          width: 100,
          fixed: 'right',
          scopedSlots: {
            customRender: 'action'
          }
        }
      ],
      searchValue: '',
      dataSource: [],
      loading: true,
      pagination: {
        current: 1,
        total: 0
      }
    }
  },
  created() {
    this.getUsers()
  },
  methods: {
    searchReset() {
      // 重置搜索
      this.searchValue = ''
      this.getUsers()
    },
    searchSubmit() {
      // 搜索
      if (!this.searchValue) return
      this.pagination.current = 1
      this.loading = true
      this.getUsers()
    },
    handleSelectChange(e) {
      console.log(e)
    },
    handlePaginationChange(e) {
      this.loading = true
      this.pagination.current = e.current
      this.getUsers()
    },
    getUsers() {
      // 获取列表
      const i = 0
      const data = {
        page: this.pagination.current,
        limit: 10,
        status: this.status,
        isSpread: userType[i].spread, // 推广
        isdiscountSpread: userType[i].disspread // 折扣
      }
      if (!data.status) {
        delete data.status
      }
      if (this.searchValue) {
        data.keyrowd = this.searchValue
        delete data.isSpread
        delete data.isdiscountSpread
      }
      this.$axios.getUsers(data).then((res) => {
        const val = res.data.data
        this.dataSource = val.list
        this.pagination.total = val.count
        this.loading = false
      })
    }
  }
}
</script>
<style scoped>
.ribbon-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 2px;
}

.ribbon-item,
.ribbon-wrap .item {
  display: flex;
  align-items: center;
}

.ribbon-wrap .select-box {
  margin-left: 32px;
}

.ribbon-wrap .select {
  width: 160px;
}

.ribbon-wrap .ant-input-group-wrapper {
  width: 289px;
}

.ribbon-wrap >>> .ant-input-group-addon {
  padding: 0;
  border: none;
  background-color: transparent;
}
</style>
